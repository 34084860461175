import React, { useState } from "react"

import Layout from "../templates/Page"
import SwiperList from "../components/ContentBuilder/SwiperList"
import Header from "../components/ContentBuilder/Header"
import OtherAvailableOptions from "../components/ContentBuilder/SwiperList/OtherAvailableOptions"
import ProductThumbnails from "../components/ContentBuilder/SwiperList/ProductThumbnails"
import Subscribe from "../components/Footer/subscribe"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"

const ProductPage = () => {
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)

  return (
    <Layout hideHeader>
      <Header isLogin />
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/explore">Explore</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Hardware</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Sink Mixers</Breadcrumb.Item>
          <Breadcrumb.Item active>Meir Round Kitchen Mixer</Breadcrumb.Item>
        </Breadcrumb>

        <div className="product-title">
          <h1>Blanco LINUSSA Kitchen Mixer</h1>
          <a href="#" className="quick-add">
            <span>Add to board</span>
            <svg width='30px' height='33px' className="ml-2"><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
          </a>
        </div>

        <Row>
          <Col lg={{ span: 8, order: 1 }} xs={{ span: 12, order: 1 }}>
            <ProductThumbnails></ProductThumbnails>
          </Col>
          <Col lg={{ span: 4, order: 2 }} xs={{ span: 12, order: 2 }}>
            <div className="product-info">
              <h2>Information</h2>
              <div className="product-info-inner column">
                <h3>ABOUT</h3>
                <p>The tap is designed to go hand-in-hand with a coloured sink from
                Blanco’s SILGRANIT range, but it will ultimately serve as a
                welcome addition to any space. A minimalist pin lever allows for
                effortless control of water flow, with the added benefit of
                reducing visual clutter. 
                {isReadmore ? "..." : " The 140-degree swivel spout and high clearance outlet will come in handy when washing larger items. The pull-out spray head gives you complete coverage over everything in your sink,"}</p>
                <span
                  className="expand"
                  onClick={() => setReadmore(!isReadmore)}
                >{isReadmore ? "Read more" : "Read less"}
                </span>
              </div>
              <div className="product-info-inner">
                <h3>BRAND</h3>
                <p>Meir Australia</p>
              </div>
              <div className="product-info-inner">
                <h3>COST OVER STANDARD</h3>
                <p>$195.00</p>
              </div>
              <div className="btn-wrap">
                <a href="#" className="btn btn-black btn-big"><svg width='30px' height='33px' className="mr-2"><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>Add to board</a>
                <a
                  className="view-more cursor-pointer"
                  onClick={() => setViewmore(!isViewmore)}
                >
                  {isViewmore ? "View more" : "View less"}
                </a>
              </div>
            </div>
          </Col>
          <Col lg={{ span: 8, order: 3 }} xs={{ span: 12, order: 3 }}>
            <OtherAvailableOptions />
          </Col>
        </Row>
        <SwiperList title="Other land this home works with" navClass="land" />
        <SwiperList title="Other homes that fit this land" navClass="home" />
        <SwiperList title="Available facade options" navClass="facade" />
        <SwiperList title="Works with these styles" navClass="works" />
        <SwiperList title="Related items" navClass="related" />
        
      </Container>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export default ProductPage
