import React, { useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, Pagination } from 'swiper/core';

SwiperCore.use([Thumbs, Pagination]);
const ProductThumbnails = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const paramsTrack = {
        slidesPerView: 3,
        watchOverflow: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: 'vertical',
        spaceBetween: 10,
    };
    const params = {
        watchOverflow: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        preventInteractionOnTransition: true,
        spaceBetween: 10,
    };
    return (
        <>
            <div className="thumbnail-wrap">
                <Swiper className="thumbnail-track" {...paramsTrack} onSwiper={setThumbsSwiper}>
                    <SwiperSlide><div className="img-wrap"><img src="/img/products/item-1.png"></img></div></SwiperSlide>
                    <SwiperSlide><div className="img-wrap"><img src="/img/products/item-2.png"></img></div></SwiperSlide>
                    <SwiperSlide><div className="img-wrap"><img src="/img/products/item-3.png"></img></div></SwiperSlide>
                    <SwiperSlide><div className="img-wrap"><img src="/img/products/item-4.png"></img></div></SwiperSlide>
                    <SwiperSlide><div className="img-wrap"><img src="/img/products/item-5.png"></img></div></SwiperSlide>
                    <SwiperSlide><div className="img-wrap"><img src="/img/products/item-6.png"></img></div></SwiperSlide>
                </Swiper>

                <Swiper className="thumbnail-big" pagination={{ clickable: true }} {...params} thumbs={{ swiper: thumbsSwiper }} >
                    <SwiperSlide><img src="/img/products/item-1.png"></img></SwiperSlide>
                    <SwiperSlide><img src="/img/products/item-2.png"></img></SwiperSlide>
                    <SwiperSlide><img src="/img/products/item-3.png"></img></SwiperSlide>
                    <SwiperSlide><img src="/img/products/item-4.png"></img></SwiperSlide>
                    <SwiperSlide><img src="/img/products/item-5.png"></img></SwiperSlide>
                    <SwiperSlide><img src="/img/products/item-6.png"></img></SwiperSlide>
                </Swiper>
            </div>

            <img className="thumbnail-single" src="/img/products/big.png" alt=""></img>
        </>
    )
}

export default ProductThumbnails
